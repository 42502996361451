@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.controllersWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    // background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
    transition: .5s ease all;
}

.hideControls {
    transform: translateY(60px);
}

.controlButton {
    @include bodyText("primary", 400, 'small', (color:$light6));
    display: flex;
    padding: 0 15px;
    margin: 0;
    height: auto;
    border: none;
    background-color: transparent;
    border-radius: 0;
    outline: none;
    height: 60px;
    align-items: center;

    &:hover {
        color: $light0;
        cursor: pointer;
        background-color: rgba($color: $dark3, $alpha: .4);
    }

}

.playButton {
    @include bodyText("primary", 400, "medium", (color:$light0));
    font-size: 48px;
    outline: none;
    border: none;
    padding: 5px 20px;
    background-color: $primary;
    text-align: center;
    border-radius: 10px;

    & :hover {
        color: $light0;
        cursor: pointer;
    }
}


.topContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;

    & p {
        @include headingText("primary", 500, "large", (color:$light0))
    }
}

.bookmarkButton {
    @include bodyText("primary", 400, "normal", (color:$light0));
    background-color: $primary;
    outline: none;
    border: none;
    padding: 5px 10px;

    &:hover {
        background-color: $dark1;
        cursor: pointer;
    }
}

.middleContainer {
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
}

.bottomContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: -1px;
}

.progress {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;

    & span {
        @include bodyText("primary", 500, "tiny", (color:$light0))
    }
}

.progressSlider {
    width: 100%;
    flex: 1;
    padding: 0 !important;
    height: 6px !important;

    cursor: pointer;


    & :global {

        .rc-slider-track,
        .rc-slider-rail {
            height: 6px;
        }

        .rc-slider,
        .rc-slider-rail,
        .rc-slider-track,
        .rc-slider-step {
            border-radius: 0;
        }

        .rc-slider-rail {
            background-color: rgba($color: $dark2, $alpha: .6);
        }

        .rc-slider-track {
            background-color: $primary;
            z-index: 9;
        }

        .rc-slider-handle {
            // display: none;
            z-index: 999;
            background-color: $primary;
            border-color: transparent;
        }
    }
}

.bottomIcons {
    display: flex;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: .8);
}

.bottomIcon {
    font-size: 26px;
}

.volumeControllers {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.volumeControllerInner {
    display: flex;
    flex: 1;
    gap: 5px;
    align-items: center;
}

.time {
    @include bodyText("primary", 400, "small", (color:$light0));
}

.volumeSlider {
    max-width: 100%;
    width: 100px !important;
    height: 10px;
    margin-right: 10px;

    & :global {

        .rc-slider-track,
        .rc-slider-rail {
            height: 2px;
        }

        .rc-slider-rail {
            background-color: $dark3;
        }

        .rc-slider-track {
            background-color: rgba($primary, 0.8);
        }

        .rc-slider-handle {
            height: 10px;
            width: 10px;
            margin-top: -4px;
            border: none;
            background-color: $primary;
        }
    }
}

.speed {
    position: relative;
}

.popup {
    background-color: rgba($color: $dark1, $alpha: .8);
    position: absolute;
    bottom: 100%;
    width: 150%;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    text-align: center;

    & span {
        @include bodyText("primary", 400, "normal", (color:$light6));
        display: block;
        padding: 5px;

        &:hover {
            cursor: pointer;
            color: $light0;
        }
    }

    .activeItem span {
        background-color: $light0;
        color: $dark0;
    }
}

.loadedBar {
    height: 6px;
    position: absolute;
    left: 0;
    right: 0;
    background-color: rgba($color: $dark1, $alpha: 1);
    transition: width .3s ease-in-out;
    z-index: -1;
}

.playPauseButton {
    display: flex;
    flex: 1;
    background: #000000;
    opacity: .5;
    border: 0;
    outline: none;
    width: 100%;
    cursor: pointer;
}