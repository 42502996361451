@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
.container {
    width: 100%;
    // padding-top: 56.25%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.playerWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: $dark0;
    overflow: hidden;
    z-index: 2;
}

.ytWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 200%;
    width: 100%;
    transform: translate(0, -25%);
    background-color: $dark0;
}

.fbWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $dark0;
}

.otherWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $dark0;

}




.playerOverlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .2;
    background-color: aqua;
    z-index: 5;
    pointer-events: none;
}


.buffer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 50px;
    height: 50px;
    border: 10px solid rgba($color:$light2, $alpha: .3);
    border-top-color: $dark4;
    border-radius: 50%;
    animation: loading 1s linear infinite;
}

@keyframes loading {
    to {
        transform: rotate(1turn);
    }
}

.playPause {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 60px;
    height: 60px;
    z-index: 10;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        animation: scale .5s linear forwards;
    }
}

@keyframes scale {
    0% {
        opacity: 0;
        transform: scale(.5);
    }

    50% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}