@use 'sass:math';
@import "/builds/ijaas/deploy-runner/package/src/styles/Global.scss"; 

 
@import "@dex/bubl-web/src/styles/Web.scss";
@import "./Fonts.scss";

html {
    background: $dark0;
    color: $light0;
    font-size: 8px;
    overflow-y: scroll;
    scroll-behavior: smooth;

    @media #{$tablet-portrait-up} {
        font-size: 8px;
    }

    @media #{$tablet-landscape-up} {
        font-size: 8px;
    }

    @media #{$laptop-up} {
        font-size: 10px;
    }

    @media #{$desktop-up} {
        font-size: 10px;
    }
}

body {
    background: $dark0;
    color: $light0;

    >iframe {
        pointer-events: none;
    }
}

#root {
    background: $dark0;
    color: $light0;
}

img.loaded {
    background-color: transparent !important;
}

.flex-has-bg {

    .flex-bg {
        object-fit: cover;
        height: 100%;
    }
}